import { ModelSelect, BasicSelect } from 'vue-search-select';
import commonHelper from '@/app/utility/common.helper.utility';
import appStrings from '@/app/utility/string.utility';
import DatePicker from 'vue2-datepicker';
export default {
  name: 'addTwoSixASInvoice',
  components: { DatePicker, ModelSelect, BasicSelect },
  props: ['invoiceDetails'],
  data() {
    return {
      showInventoryOrgModal: false,
      showValueSetModal: false,
      parent_value_set_id: null,
      loader: false,
      editMode: false,
      showAlert: false,
      isSuccess: false,
      responseMsg: '',
      inventoryOrg: {
        value: null,
        text: null
      },
      itemCode: {
        value: null,
        text: null
      },
      itemCodeList: [],
      createParentRadio: false,
      childLot: null,
      createParent: null,
      selectParentRadio: false,
      selectParent: {
        value: null,
        text: null
      },
      selectParentList: [],
      status: null,
      start_date_end_date: []
    };
  },
  mounted() {
    this.getInventoryItemCode();
    this.getInventoryParentLots();
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'save') {
          this.addEditMappingMaintainLots();
        }
      }
    });
  },
  methods: {
    getInventoryItemCode() {
      this.loader = true;
      this.$store
        .dispatch('invAdmin/getInventoryItemCode')
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            const itemList = response.data.data.map(data => {
              return {
                text: data.item_code,
                value: data.inventory_item_id
              };
            });
            this.itemCodeList = itemList;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    addEditMappingMaintainLots() {
      const payload = {
        child_lot: this.childLot,
        end_date: commonHelper.formattedDate(this.start_date_end_date[1]),
        inventory_item_id: this.itemCode.value,
        lot_number_id: 0,
        org_id: this.inventoryOrg.value,
        parent_lot:
          this.createParentRadio === true
            ? this.createParent
            : this.selectParentRadio === true
            ? this.selectParent.value
            : null,
        start_date: commonHelper.formattedDate(this.start_date_end_date[0]),
        status: this.status
      };
      this.loader = true;
      this.$store
        .dispatch('invAdmin/addEditMappingMaintainLots', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.editMode = false;
            this.showAlert = true;
            this.isSuccess = true;
            this.responseMsg = response.data.message;
          } else {
            this.showAlert = true;
            this.isSuccess = false;
            this.responseMsg = response.response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
          this.showAlert = true;
          this.isSuccess = false;
          this.responseMsg = appStrings.autoFailedMsg;
        });
    },
    getInventoryParentLots() {
      this.loader = true;
      this.$store
        .dispatch('invAdmin/getInventoryParentLots')
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            const itemList = response.data.data
              .filter(key => key)
              .map(data => {
                return {
                  text: data.parent_lot,
                  value: data.parent_lot
                };
              });
            this.selectParentList = itemList;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    changeValue() {
      if (this.selectParentRadio) {
        this.createParentRadio = false;
      } else if (this.createParentRadio) {
        this.selectParentRadio = false;
      }
    },
    openValueSetModal(vsetCode) {
      // To Open Value Set Modal
      this.vsetCode = vsetCode;
      if (this.vsetCode === appStrings.VALUESETTYPE.INV_MASTER_ORG) {
        this.parent_value_set_id = 'N';
      }
      this.showValueSetModal = true;
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    closeValueSetModal() {
      // To Close Value Set Modal
      this.showValueSetModal = false;
    },
    selectedvalueSet(item) {
      // To Selected Value from value set modal
      if (this.vsetCode === appStrings.VALUESETTYPE.INV_MASTER_ORG) {
        this.inventoryOrg.value = item.value_set_dtl_id;
        this.inventoryOrg.text = item.value_code;
      }
    },
    setDate(dateRange) {
      this.start_date_end_date = commonHelper.setFutureDate(dateRange);
    },
    clearVsetValues(vsetCode){
      if (vsetCode === 'INV_MASTER_ORG') {
        this.inventoryOrg.value = null;
        this.inventoryOrg.text = null;
      }
    }
  },
  beforeDestroy() {
    this.unsubscribe();
    clearTimeout(this.setTimeVsetCode);
  }
};
