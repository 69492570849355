import addMaintainLots from '../maintainLots/addMaintainLots';
import commonHelper from '@/app/utility/common.helper.utility';
import appStrings from '@/app/utility/string.utility';
export default {
  name: 'twoSixASInvoices',
  components: { addMaintainLots },
  data() {
    return {
      showMaintainLotsModal: false,
      showValueSetModal: false,
      parent_value_set_id: null,
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      currentPage: 1,
      totalRows: null,
      loader: false,
      editMode: false,
      inventoryOrg: {
        value: null,
        text: null
      },
      parentLotNumber: {
        value: null,
        text: null
      },
      childLotNumber: {
        value: null,
        text: null
      },
      lotsData: [],
      lotsFields: [
        {
          key: 'item_code'
        },
        {
          key: 'org_name',
          label: 'Inventory Org'
        },
        {
          key: 'parent_lot',
          label: 'Parent Lot Number'
        },
        {
          key: 'child_lot',
          label: 'Child Lot Number'
        },
        {
          key: 'start_date'
        },
        {
          key: 'end_date'
        },
        {
          key: 'status'
        }
      ]
    };
  },
  mounted() {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'add') {
          this.showMaintainLotsModal = true;
        }
      }
    });
  },
  methods: {
    getInventoryMaintainLot() {
      // Get API for 26AS Invoices
      const payload = {
        _page: this.currentPage - 1,
        _limit: this.perPage,
        org_id: this.inventoryOrg.value,
        parent_lot: this.parentLotNumber.value,
        child_lot: this.childLotNumber.value
      };
      this.loader = true;
      this.$store
        .dispatch('invAdmin/getInventoryMaintainLot', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.lotsData = response.data.data.page;
            this.totalRows = response.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },

    showHideMaintainLotsModal(flag) {
      this.showMaintainLotsModal = flag;
    },
    rowSelected(item) {
      this.invoiceDetails = item; // For Props
      this.showMaintainLotsModal = true; // For Open Child Modal
    },
    openValueSetModal(vsetCode) {
      // To Open Value Set Modal
      this.vsetCode = vsetCode;
      if (this.vsetCode === appStrings.VALUESETTYPE.INV_MASTER_ORG) {
        this.parent_value_set_id = 'N';
      }
      this.showValueSetModal = true;
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    closeValueSetModal() {
      // To Close Value Set Modal
      this.showValueSetModal = false;
    },
    selectedvalueSet(item) {
      // To Selected Value from value set modal
      if (this.vsetCode === appStrings.VALUESETTYPE.INV_MASTER_ORG) {
        this.inventoryOrg.value = item.value_set_dtl_id;
        this.inventoryOrg.text = item.value_code;
      }
    },
    clearFilters() {
      // Clear Filters
      this.inventoryOrg.text = null;
      this.inventoryOrg.value = null;
      this.parentLotNumber.text = null;
      this.parentLotNumber.value = null;
      this.childLotNumber.text = null;
      this.childLotNumber.value = null;
      this.totalRows = null;
      this.currentPage = 1;
      this.lotsData = [];
    },
    clearVsetValues(vsetCode) {
      if (vsetCode === 'INV_MASTER_ORG') {
        this.inventoryOrg.value = null;
        this.inventoryOrg.text = null;
      }
    }
    },
  beforeDestroy() {
    this.unsubscribe();
    clearTimeout(this.setTimeVsetCode);
  }
};
